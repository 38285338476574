<template>
  <div class="nav_wrap black_gradient">
    <nav class="main-nav">
      <ul>
        <li>
          <a href="/o-kompanii" :class="{active: currentSlugHas('o-kompanii')}">
            <span>О компании</span>
          </a>
        </li>
        <li>
          <a href="/stroitelnye-materialy" :class="{active: currentSlugHas('stroitelnye-materialy')}">
            <span>Строительные материалы</span>
          </a>
        </li>
        <li>
          <a href="/metallicheskie-resheniya" :class="{active: currentSlugHas('metallicheskie-resheniya')}">
            <span>Металлические решения</span>
          </a>
        </li>
        <li>
          <a href="/uslugi" data-toggle="dropdown" :class="[{active: currentSlugHas('uslugi')}, 'dropdown-toggle']" aria-expanded="false">
            <span>Услуги</span>
            <b class="caret"></b>
          </a>
          <ul class="dropdown-menu">
            
            <li v-for="sub_page in subpageUslugi" :key="sub_page.id" :class="{active: currentSubpageSlugHas(sub_page.slug)}">
              <a :href="subpageUslugiPath(sub_page.slug)">
                <span>{{sub_page.title}}</span>
              </a>
            </li>
          </ul>
        </li>
        <li>
          <a href="/predlozheniya" :class="{active: currentSlugHas('predlozheniya')}">
            <span>Акции</span>
          </a>
        </li>
        <li>
          <a href="/novosti" :class="{active: currentSlugHas('novosti')}">
            <span>Полезная информация</span>
          </a>
        </li>
        <li>
          <a href="/galereya" :class="{active: currentSlugHas('galereya')}">
            <span>Галерея</span>
          </a>
        </li>
        <li>
          <a href="/kontakty" :class="{active: currentSlugHas('kontakty')}">
            <span>Контакты</span>
          </a>
        </li>        
      </ul>
    </nav>
  </div>
</template>

<script>
  export default {
    name: 'forbiznav',
    data: function() {
      return {
        activeIndex: '1'
      }
    },
    props: {
      currentpage: {
        type: String,
        required: false
      },
      subpageUslugi: {
        type: Array,
        required: true
      }
    },
    computed: {
    },
    methods: {
      currentSlugHas: function(slug) {
        return slug == this.currentpage
      },
      subpageUslugiPath: function(subpage_slug) {
        console.log({subpage_slug})
        return `/uslugi/${subpage_slug}`
      },
      currentSubpageSlugHas: function(subpage_slug) {
        return this.subpageUslugiPath(subpage_slug) == '/' + window.location.pathname.split('/').splice(1,2).join('/')
      }
    },
    mounted(){
      console.log('this.currentpage', this.currentpage)
      console.log('this.subpageUslugi', this.subpageUslugi)
    }
  }
</script>

<style lang="scss">
  .nav_wrap{
    text-align: center;
    border-bottom: 0px  solid;
    border-top: 0p solid;
    height: 50px;
    position: absolute;
    width: 100%;
    z-index: 200;
  }
  .main-nav{
    text-align: center;
    font-weight: 400;
    color: #333;
    display: inline-block;
    width: 1000px;
    text-align: left;
    > ul{
      margin-left: 16px;
      > li{
        background: transparent url("./../assets/images/menu_sep.png") no-repeat center right;
        display: block;
        float: left;
        position: relative;
        &:last-child{
          background: none;
        }
        ul.dropdown-menu {
          background: #EAEBEB;
          text-align: center;
          li {
            display: list-item;
            a {
              color: #6E6E6E;
              text-decoration: none;
              display: block;
              span{
                padding: 6px 8px;
              }
            }
            &.active {
              a {
                background-color: rgba(12,12,12,0.2);
                background-image: linear-gradient(to bottom, #D1D1D1, #D1D1D1) !important;
                background: -webkit-gradient(linear, left top, left bottom, from(#D1D1D1), to(#D1D1D1));
                filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#D1D1D1', endColorstr='#D1D1D1', GradientType=0);
              }
            }
          }
        }
      }
    }
    a{
      position: relative;
      color: #fefefe;
      font-size: 14px;
      text-transform: uppercase;
      text-decoration: underline;
      font-family: "pf_din_text_cond_pro_mediumRg", Arial, Helvetica, sans-serif;
      vertical-align: top;
      margin: 0px;
      display: inline-block;
      &:hover {
        color: #e51e22;
      }
      &.active{
        cursor: default;
        color: #333;
        background-image: linear-gradient(to bottom, #D1D1D1, #f4f4f4) !important;
        background: -webkit-gradient(linear, left top, left bottom, from(#D1D1D1), to(#f4f4f4)) !important;
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#D1D1D1', endColorstr='#f4f4f4', GradientType=0) !important;
      }
      &.open {
        background-color: rgba(12,12,12,0.2);
        text-decoration: none;
      }
      &.active.open {
       background-color: rgba(12,12,12,0.01);
      }
      span {
        padding: 15px 10px;
        display: block;
      }
      &.dropdown-toggle{
        padding-right: 12px;
      }
      .caret{
        position: absolute;
        top: 25px;
        right: 8px;
      }
    }
  }
</style>
