<template>
  <div class="item_wrap" >
    <div class="item_list_page" v-if="!itemListType && load">
      <h1 class='title'>{{getItem.title}}</h1>
      <div class="clearfix">
        <div id="div_main" v-if="getItem != []" class="clearfix">
          <div class="div_overlay">
            <span class="is_sale" title="Распродажа!" v-if="getItem.is_sale" ></span>
            <span class="is_hit"  title="Распродажа!" v-if="getItem.is_hit" ></span>
          </div>
          <a href="" :alt="getItem.title" :data-mfp-src="itemImageUrl(getItem.item_image)"  class="lazy-image-wrapper no-outline">
            <img :src="itemImageUrl(getItem.item_image)" :alt="getItem.title" :title="getItem.title" :data-original="itemImageUrl(getItem.item_image)">
          </a>
          <div id="main_props">
            <ul>
              <li v-for="mainProp in mainPropNames" :key='mainProp.id'>
                <span
                  class='ptitle bold'
                  v-html="mainProp.title"></span>: 
                  <span
                    class='pvalue'
                    v-html="findItemPropValue(getItem, mainProp.id)"
                  >
                </span>
                <span 
                  class='puom'
                  v-if="getUomTitle(mainProp.uom_id) != null"
                  v-html="getUomTitle(mainProp.uom_id)"
                ></span>
              </li>
            </ul>
          </div>
        </div>
        <div id="gallery_block">
          <div id="gallery3_wrapper">
            <div id="gallery3">
              <div v-for="frame in gphotos" :key="frame.id" class="col-md-12 col-sm-12 col-xs-12 frame">
                <div class="img-holder col-md-12 col-sm-12 col-xs-12">
                  <a v-if="gphotos != []" :href="frame.image.url" :title="frame.title">
                    <img :src="frame.image.thumb_222fill.url" :alt="frame.title" :title="frame.title"/>
                  </a>
                </div>
                <div class="name" v-html="frame.title"></div>
              </div>
            </div>
          </div>
          <div id="gallery3_nav_wrapper" class="col-md-12 col-sm-12 col-xs-12">
            <div id="gallery3_nav">
              <div class="frame" v-for="frame in gphotos" :key="frame.id">
                <div class="img-holder">
                  <img :title="frame.title" :alt="frame.title" :src="frame.image.thumb_99.url"/>
                  <div class="name" v-html="frame.tag"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id='price_info' class="clearfix">
        <order-form
          v-if="getItem.is_ask_price"
          form-type='is_ask_price'
          :order-form-url="getItemPath(getItem.category_slug, getItem.id)"
          :order-form-title='getItem.title'
          button-text='Уточнить цену' >
        </order-form>
        <ul v-else-if="!getItem.is_ask_price">
          <li v-for="priceProp in pricePropNames" :key="priceProp.id">
            <div v-if="pricePropShow(priceProp, getItem)">
              <p class="ptitle medium">{{priceProp.title}}</p>
              <p class="bold">
                <span class="pvalue"
                  v-html="formatPrice(findItemPropValue(getItem, priceProp.id))"
                ></span>
                <span class="pvalue puom" v-html="getUomTitle(priceProp.uom_id)"></span>
              </p>
              <p 
                class="old pvalue bold" 
                v-if="getItem.is_sale && showOldPrice(findItemPropValue(getItem, priceProp.id), findItemPropValue(getItem, priceProp.related))"
              >
                <span>
                  {{ formatPrice( findItemPropValue(getItem, priceProp.related)) }} 
                  <span 
                    class="puom"
                    v-html="getUomTitle(priceProp.uom_id)"
                  ></span>
                </span>
              </p>
            </div>
          </li>
        </ul>
      </div>

      <div id="div_vendor_logo">
        <a v-if="vendorPhotos != []" :href="'/stroitelnye-materialy/proizvoditeli/'+ vendor.id" class="no-outline" :title="vendor.title">
          <img v-if="vendorPhotos != []" :src="vendorPhotos.image.thumb_150.url" :alt="vendor.title" :title="vendor.title"/>
        </a>
      </div>

      <div id="div_button_order">
        <order-form form-type="item_order" :order-form-url="getItemPath(getItem.category_slug, getItem.id)" :order-form-title="getItem.title" button-text="Заказать" ></order-form>
      </div>

      <div id="div_desc" v-html="getItem.desc"></div>

      <div id="div_common_props">
        <h4 class="bold">Дополнительная информация о товаре</h4>
        <table>
          <tbody>
            <tr :class="{p0: index % 2 === 0 , p1: index % 2 !== 0 }" :key="index" v-for="(commonProp, index) in commonPropNames">
              <td><span class="ptitle medium">{{commonProp.title}}</span></td>
              <td><span class="pvalue">{{findItemPropValue(getItem, commonProp.id)}}</span> <span class='puom' v-if="getUomTitle(commonProp.uom_id) != null">{{getUomTitle(commonProp.uom_id)}}</span></td>
            </tr>
          </tbody>
        </table>
      </div>

      <div v-html="google_off"></div>
      <div v-if="relatedItems != []" id="related_items" class="items-list clearfix">
        <h4 class="bold">Ближайшие по цене и сопутствующие товары</h4>
        
        <ul 
          class="ul_items clearfix"
          v-if="load"
          >
          <!-- <item
            @order-click="orderFormClick(item,type)"
            v-for="item in relatedItems"
            :key="item.id"
            :item="item"
            :hit="item.is_hit"
            :sale="item.is_sale"
            :uoms="mutableUoms"
            :item-price-props="itemPriceProps"
            :prop-price-prop-names="pricePropNames"
            :item-list-type="true"
          ></item> -->

          <li v-for="relatedItem in relatedItems" :key="relatedItem.id">
            <div class="div_overlay">
              <span class="is_sale" title="Распродажа!" v-if="relatedItem.is_sale" ></span>
              <span class="is_hit"  title="Распродажа!" v-if="relatedItem.is_hit" ></span>
            </div>
            <a :href="getItemPath(relatedItem.category_slug, relatedItem.id)" :title='relatedItem.title'>
              <img :src="itemImageUrl(relatedItem.item_image, 'thumb_fit')" :alt="getItem.title" :title="getItem.title">
            </a>
            <h5 class="medium">
              <a :title="relatedItem.title" :href="getItemPath(relatedItem.category_slug, relatedItem.id)">{{relatedItem.title}}</a>
            </h5>
            <order-form v-if="relatedItem.is_ask_price" form-type="is_ask_price" :order-form-url="getItemPath(relatedItem.category_slug, relatedItem.id)" :order-form-title="relatedItem.title" button-text="Уточнить цену"></order-form>
            <ul v-if="!relatedItem.is_ask_price" class="price_info">
              <li v-for="priceProp in pricePropNames" :key="priceProp.id">
                <div v-if="pricePropShow(priceProp, relatedItem)">
                  <p class="ptitle medium">{{priceProp.title}}</p>
                  <p class="new">
                    <span class="pvalue bold">
                      {{ formatPrice( findItemPropValue(relatedItem, priceProp.id) ) }}
                      <span class="puom">{{getUomTitle(priceProp.uom_id)}}</span>
                    </span>
                  </p>
                  <p class="old" >
                    <span class="pvalue bold">
                      <span v-if="relatedItem.is_sale && showOldPrice(findItemPropValue(relatedItem, priceProp.id), findItemPropValue(relatedItem, priceProp.related))">
                        {{ formatPrice( findItemPropValue(relatedItem, priceProp.related) )}}
                        <span class="puom">{{getUomTitle(priceProp.uom_id)}}</span>
                      </span>
                    </span>
                  </p>
                </div>
              </li>
            </ul>
          </li>
        </ul>
      </div>
      <div v-html="google_on"></div>
    </div>

    <div class="item" v-if="itemListType" >
      <div class="div_overlay">
        <span class="is_sale" title="Распродажа!" v-if="sale" ></span>
        <span class="is_hit"  title="Распродажа!" v-if="hit" ></span>
      </div>
      <a :href="getItemPath(item.category_slug, item.id)" :title='item.title' >
        <img  :src="itemImageUrl(item.item_image, 'thumb_fit')" 
              :alt="item.title" 
              :title="item.title" 
              class="item_image"  
              style="display: inline;"
        >
      </a>
      <h5 class="medium">
        <a :title="item.title" :href="getItemPath(item.category_slug, item.id)">{{item.title}}</a>
      </h5>
      <order-form
        v-if="item.is_ask_price"
        form-type="is_ask_price"
        :order-form-url="getItemPath(item.category_slug, item.id)"
        :order-form-title="item.title"
        button-text="Уточнить цену"
      >
      </order-form>
      <ul v-else-if="!item.is_ask_price" class="price_info">
        <li v-for="priceProp in itemPriceProps" :key="priceProp.id">
          <div v-if="pricePropShow(priceProp, item)">
            <p class="ptitle medium" v-html="priceProp.title"></p>
            <p class="new">
              <span class="pvalue bold">
                {{ formatPrice( findItemPropValue(item, priceProp.id) )}}
                <span class="puom" v-html="getUomTitle(priceProp.uom_id)"></span>
              </span>
            </p>
            <p class="old" >
              <span class="pvalue bold">
                <span v-if="item.is_sale && showOldPrice(findItemPropValue(item, priceProp.id), findItemPropValue(item, priceProp.related))">
                  {{ formatPrice( findItemPropValue(item, priceProp.related) )}}
                  <span class="puom" v-html="getUomTitle(priceProp.uom_id)"></span>
                </span>
              </span>
            </p>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
  import orderForm from 'orderForm'
  const scrollToElement = require('scroll-to-element');

  export default {
    name: 'item',
    props: {
      commitText: {
        type: String,
        required: false
      },
      item: {
        type: Object,
        required: false
      },
      itemId:{
        type: Number,
        required: false
      },
      sale: {
        type: Boolean,
        required: false
      },
      hit:{
        type: Boolean,
        required: false
      },
      itemPriceProps:{
        type: Array,
        required: false,
        default: function(){
          return []
        }
      },
      itemMainProps:{
        type: Array,
        required: false
      },
      itemCommonProps:{
        type: Array,
        required: false
      },
      itemListType:{
        type: Boolean,
        required: false
      },
      categoryId:{
        type: Number,
        required: false
      },
      uoms:{
        type: Array,
        required: false
      },
      propPricePropNames: {
        type: Array,
        required: false,
        default: function() {
          return []
        }
      }
    },
    data: function() {
      return {
        subCatProps: [],
        load: false,
        mainPropNames: [],
        filterPropNames: [],
        pricePropNames: this.propPricePropNames,
        commonPropNames: [],
        mutableUoms: this.uoms,
        getItem: [],
        gphotos: [],
        vendorPhotos: [],
        vendor: [],
        relatedItems: [],
        google_off: '<!-- googleoff: index-->',
        google_on: '<!-- google_on: index-->', // i did it becouse vue strip comments from html templates in vue files
      }
    },
    components: {
      orderForm
    },
    computed: {
      getItemImageUrl: function() {
        if (this.getItem != []){
          return this.getItem.item_image.file.url
        } else { return ''}
      },
    },
    methods: {
      itemImageUrl: function(itemImage, version) {
        if (itemImage != null) {
          if (version == null) {
            return itemImage.file.url
          } else{
            return itemImage.file[version].url
          }
        }
        else {
          return '/images/item_not_found.jpg'
        }
      },
      scrollToBreadcrumbs: function(){
        if ($('ul.breadcrumbs').length > 0) {
          scrollToElement('ul.breadcrumbs',{
            offset: 15,
            ease: 'out-quad',
            duration: 1000
          })
        }
      },
      showOldPrice: function(new_price, old_price) {
        if (new_price != old_price)
          return true
        else
          return false
      },
      formatPrice: function(price){
        return parseFloat(price).toFixed(2)
      },
      findItemPropValue: function(collection, prop_id) {
        var prop_name = collection.item_props.find(
          item_prop => { return item_prop['prop_name_id'] == prop_id }
        )
        return prop_name == undefined ? '0' : prop_name.value
      },
      pricePropShow: function(priceProp, item) {
        var price = this.findItemPropValue(item, priceProp.id)
        return priceProp.is_normal_price && parseInt(price) != 0
      },
      getUomTitle: function(uom_id) {
        return uom_id == null ? '' : this.mutableUoms.find(uom => { return uom.id == uom_id }).title
      },
      getItemPath: function(category_slug, id) {
        return '/stroitelnye-materialy/' + category_slug + '/' + id
      },
      httpGetItem: function(id) {
        this.$http.get( '/api/v1/items/' + this.itemId , {} ).then( response => {
          this.getItem = response.body
            this.$http.get('/api/v1/items/' + this.itemId + '/item_data').then( response => {
            this.gphotos = response.body.gphotos
            this.vendor = response.body.vendor
            this.relatedItems = response.body.relatedItems
            this.vendorPhotos = response.body.vendorPhotos
            this.load = true
            this.scrollToBreadcrumbs()
            setTimeout(function() {
              // all crazy fuonctiuon code upstairs should be develete
              $('div#gallery3').magnificPopup({
                delegate: 'a',
                fixedContentPos: false,
                removalDelay: 300,
                mainClass: 'mfp-fade',
                type: 'image',
                gallery: {enabled:true}
              });
              $("div#div_main").magnificPopup({
                delegate: 'a.lazy-image-wrapper',
                fixedContentPos: false,
                removalDelay: 300,
                mainClass: 'mfp-fade',
                type: 'image'
              });
              $('#gallery3').slick({
                slidesToShow: 1,
                slidesToScroll: 1,
                autoplay: false,
                fade:true,
                arrows:true,
                speed: 200,
                dots:false,
                infinite: true,
                asNavFor: '#gallery3_nav'
              });
              $('#gallery3_nav').slick({
                slidesToShow: 3,
                slidesToScroll: 1,
                asNavFor: '#gallery3',
                dots: false,
                arrows:false,
                focusOnSelect: true
              });
              $("#gallery3_nav").find(".img-holder").bind({
                mouseenter: function() { $(this).addClass("plusOpacity"); },
                mouseleave: function() { $(this).removeClass("plusOpacity"); }
              })
            }, 190);
          }, response => {});
        }, response => {} );
      },
      httpGetStrSubCatProps: function() {
        this.$http.get( '/api/v1/categories/' + this.categoryId + '/category_prop_names_grouped',{}).then(
          response => {
            this.filterPropNames = response.body.filter_props
            this.pricePropNames = response.body.price_props
            this.commonPropNames = response.body.common_props
            this.mainPropNames = response.body.main_props
          },
          response => {}
        );
      }
    },
    mounted: function () {
      this.$nextTick(function () {
        if ( !this.itemListType ) {
          this.httpGetStrSubCatProps()
          this.$http.get('/api/v1/uoms').then( response => { 
            this.mutableUoms = response.body 
            this.httpGetItem()           
          }, response => { return null });
        }
      });
    },
  }
</script>

<style lang="scss">
  .item_list_page{
    overflow: hidden;
    padding-bottom: 20px;
    #div_main{
      position: relative;
      a{
        img{
          display: inline;
        }
      }
    }

  }
  .item_list_page, .item{
    .div_overlay{
      position: absolute;
      top: 0;
      left: 15px;
      z-index: 100;
      span{
        display: block;
        float: left;
        width: 22px;
        height: 29px;
        margin-right: 5px;
        cursor: pointer;
      }
    }
  }
  .is_hit{
    background: transparent url('../assets/images/hit.png') no-repeat 0 0;
  }
  .is_sale{
    background: transparent url("../assets/images/sale.png") no-repeat 0 0;
  }
  h1.title {
    padding-bottom: 14px;
  }
  .item{
    position: relative;
    float: left;
    padding: 0;
    padding: 10px 10px 12px 10px;
    margin: 0 -3px -3px 0;
    width: 208px;
    height: 309px;
    border: 3px solid #F2F2F2;
    overflow: hidden;
    a{
      img{
        display: inline;
      }
    }
    h5{
      padding: 2px 0px 0px 0px;
      margin: 0px 0px 3px 0px;
      font-size: 16px;
      a{
        color: #191919;
        text-decoration: underline;
        -webkit-transition: color 0.2s ease-out;
        -moz-transition: color 0.2s ease-out;
        -ms-transition: color 0.2s ease-out;
        -o-transition: color 0.2s ease-out;
        transition: color 0.2s ease-out;
        outline: none;
        height: 133px;
        overflow: hidden
      }
    }
    > a{
      display: inline-block;
      outline: none;
      height: 123px;
      overflow: hidden;
    }
  }
  .item_image{
    width: 182px;
    display: block;
  }
  #div_desc {
    width: 100%;
    float: left;
    margin-top: 20px;
    font-size: 15px;
    min-height: 20px;
    border-bottom: 1px solid #cacaca;
    margin-bottom: 10px;
    padding-bottom: 10px;
    overflow: hidden;
    white-space : normal;
    p > img{
      margin-bottom: 10px;
    }
  }
  #div_common_props {
    float: left;
    h4 {
      color: #b52126;
      margin-bottom: 20px;
      cursor: default;
    }
    table {
      margin: 0;
      padding: 0;
      list-style: none;
      width: 450px;
      tr {
        td {
          min-height: 39px;
          margin: 0;
          padding: 9px 7px 9px 7px;
          font-size: 16px;
          color: #767676;
        }
        span.ptitle {
          display: inline-block;
          max-width: 220px;
        }
        &.p0 {
          td {
            box-shadow: 
              0px -19px 17px -8px rgba(0, 0, 0, 0.12) inset,
              0 17px 17px -8px rgba(0, 0, 0, 0.12) inset;
            -o-box-shadow: 
              0px -19px 17px -8px rgba(0, 0, 0, 0.12) inset,
              0 17px 17px -8px rgba(0, 0, 0, 0.12) inset;
            -ms-box-shadow:
              0px -19px 17px -8px rgba(0, 0, 0, 0.12) inset,
              0 17px 17px -8px rgba(0, 0, 0, 0.12) inset;
            -moz-box-shadow: 
              0px -19px 17px -8px rgba(0, 0, 0, 0.12) inset,
              0 17px 17px -8px rgba(0, 0, 0, 0.12) inset;
            -webkit-box-shadow: 
              0px -19px 17px -8px rgba(0, 0, 0, 0.12) inset,
              0 17px 17px -8px rgba(0, 0, 0, 0.12) inset;
          }
        }
      }
    }
  }

  #related_items{
    float: right;
    width: 460px;
    padding-left: 15px;
    > h4.bold{
      padding-left: 30px;
      //text-transform: uppercase;
      //text-decoration: underline;
      cursor: default;
    }

    ul.ul_items{
      list-style: none;
      padding-left: 30px;
      margin: 0;
      background: none;
      > li {
        position: relative;
        float: left;
        padding: 0px;
        margin: 0px -3px -3px 0px;
        width: 208px;
        height: 309px;
        border: 3px solid #F2F2F2;
        overflow: hidden;
        .is_ask_price{
          margin-left: 10px;
        }
        > a {
          outline: none;
          height: 133px;
          overflow: hidden;
          display: block;
          img {
            width: 182px;
            background-color: #F2F2F2;
            margin: 10px;
          }
        }

        h5 {
          padding: 3px 10px 5px 10px;
          margin: 0px 0px 2px 0px;
          font-size: 16px;
          a {
            color: #191919;
            text-decoration: underline;
            &:hover, &:active, &:focus {
              text-decoration: underline;
            }
            &:hover {
              color: #000000;
            }
            //@include transition_color(.2s);
          }
        }
        .price_info {
          bottom: 0;
          left: 10px;
          width: 300px;
          ul {
            margin: 0;
            height: 66px;

            li {
              margin-right: 23px;
              p {
                margin-bottom: -3px;
              }
            }
          }
        }

      }

    }
  }

  #forbiz_app{
    div#price_info{
      position: relative;
      margin-top: 0px;
      width: 170px;
      height: 60px;
      a{
        height: auto;
      }
      ul{  margin-top: 0px; }
    }
  }
  ul.price_info{
    position: absolute;
    bottom: 0;
    width: 210px;
    margin: 0;
    display: inline-block;
    list-style: none;
    padding: 0px 0px 2px 0px;
    li{
      margin-right: 15px;
      display: block;
      float: left;
    }
    h5{
      padding: 0 10px 5px;
      margin: 0;
      font-size: 16px
    }
    .medium{
      font-family: "pf_din_text_cond_pro_mediumRg", Tahoma, Arial, sans-serif;
    }
    .ptitle{
      font-size: 14px;
      color: #afafaf
    }
    p{
      margin: 0px;
      margin-top: -3px;
      span{
        &.pvalue{
          font-size: 18px;
          color: #ab1e22
        }
      }
      &.old{
        text-decoration: line-through;
        text-decoration-color: #afafaf; 
        min-height: 21px;
        span{
          font-size: 14px;
          color: #afafaf
        }
        &:after{
          position: absolute;
          display: none;
          left: 0;
          top: 50%;
          height: 1px;
          width: 100%;
          background-color: #afafaf;
          content: "";
        }
      }

    }
  }
  #gallery3_wrapper {
    padding: 0;
    height: 672px;
    margin: 0 0 30px 35px;
    text-align: center;
  }
  div#gallery_block {
    float: left;
    /*background-color: rgba(10, 110, 20, 0.08);*/
    width: 315px;
    margin-bottom: -20px;

    #gallery3_wrapper {
      height: 176px;
      margin: 0 0 10px 0px;

      #gallery3 {
        width:  222px;
        font-family: "HelveticaRegular", Arial, Helvetica, sans-serif;
        display: inline-block;
        img{
          display: block;
          margin: 0 auto;
          max-width: 100%;
        }
        .slick-prev, .slick-next {
          top: 50%;
        }
        .frame {
          height: 176px;
          padding: 0;
          position: relative;
          cursor: pointer;
 
          .img-holder {
            padding: 0;
            width: 222px;
            height: 176px 
          }
          .name {
            display:none;
          }
        }
        button {
          opacity: 0;
          visibility: hidden;
        }
      }

    }

    #gallery3_nav_wrapper {
      width: 249px;
      margin-left: 47px;
      margin-bottom: -100px;
      padding-left: 0px;
      #gallery3_nav {
        margin-bottom: 10px !important;
        width: 222px;
        height: 47px;
        overflow: hidden;
        font-size: 0;
        .frame {
          padding: 0;
          position: relative;
          cursor: pointer;
          .img-holder {
            width: 66px;
            padding: 0;
            img {
              width: 66px;
            }
            .name {
              display: none;
            }
          }

        }
      }

      a#goto_gallery {
        float: right;
        margin-right: 13px;
        text-decoration: underline;
      }
    }

  }

  .bold{
    font-family: "pf_din_text_cond_pro_mediumRg", Tahoma, Arial, sans-serif;
  }
</style>
