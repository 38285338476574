$(document).ready(function(){

  $('.str_cat_nav .a_cat_nav').each(function () {
    var content_div_id = "#" + $(this).attr('id') + "_content";
    $(this).popover({
      container: 'body',
      html: true,
      trigger: 'focus',
      placement: 'bottom',
      content: function () { return $(content_div_id).html(); },
      title: function() { return ''; }
    })
    .on('hide.bs.popover', function () {
      $(this).removeClass('opened');
      $(this).parent('li').removeClass('opened')
    })
    .on('show.bs.popover', function () {
      $(this).addClass('opened');
      $(this).parent('li').addClass('opened')
    })
  });

  /**
   * $.parseParams - parse query string paramaters into an object.
   */
  (function($) {
    var re = /([^&=]+)=?([^&]*)/g;
    var decodeRE = /\+/g;  // Regex for replacing addition symbol with a space
    var decode = function (str) {return decodeURIComponent( str.replace(decodeRE, " ") );};
    $.parseParams = function(query) {
        var params = {}, e;
        while ( e = re.exec(query) ) { 
            var k = decode( e[1] ), v = decode( e[2] );
            if (k.substring(k.length - 2) === '[]') {
                k = k.substring(0, k.length - 2);
                (params[k] || (params[k] = [])).push(v);
            }
            else params[k] = v;
        }
        return params;
    };
  })(jQuery);


  $('#feedback_form #form_message').bind('ajax:success', function(){
    //console.log('happens')
    //$('#feedback_form').remove()
    $('#modal_ok').modal('show')
    $('#feedback_form #form_message .clean input,#feedback_form #form_message .clean textarea').val('')
  })

  $('#modal_mess #form_message').bind('ajax:success', function(){
    $('#modal_mess').modal('hide')
    $('#modal_ok').modal('show')
    $('#form_message .clean input, #form_message .clean textarea').val('')
    $('#afiles').html('')
  })

  $('#li_ostavit-zayavku').on('click touch', function(){
    $('#modal_mess').modal('show')
  })

  var $a = $("a#link_attach");
  var $btn = $("input#message_mpictures_attributes_");
  var $afiles = $("div#afiles");

  $btn.change(function (e) {

        if (e.target.files == undefined) {
            //console.log('<input#images_.onChnage> e.target.files = undefined');
        } else {
            //console.log($btn);
            fCollectFileNames(e.target.files);
        }
    }
  );

  $a.click(function (e) {
      e.preventDefault();
      $btn.click();
  });

  var fCollectFileNames = function (fileList) {
      var str = '<ul>';
      for (var i=0; i<fileList.length; i++) {
         //console.log(fileList[i].name);
          str += '<li>' + fileList[i].name + '</li>';
      }
      str += "</ul>";
      $afiles.html(str);
  };

  $btn.change();  
  
})


