// start js/vuejs compile
import "jquery";
import "jquery-ujs";
import "slick-carousel";
import "js-cookie";
import "magnific-popup";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "local-time";
// here is compile css
import "../src/css/application.scss";

import "../src/js/frontend/lib/bootstrap-select.js";
import "../src/js/frontend/fix_bootstrap_select_for_ie.js";
import "../src/js/frontend/lib/jquery.lazyload.js";
// import '../src/js/frontend/lib/jquery.magnific-popup.js'
import "../src/js/frontend/lib/serialize.js";
import "../src/js/frontend/lib/spin.js";
import "../src/js/frontend/lib/spin_based_preloader.js";
import "../src/js/frontend/site/init.js";
import "../src/js/frontend/site/lazy-start.js";
import "../src/js/frontend/site/modal_forms.js";
import "../src/js/frontend/site/placemark.js";
// import '../src/js/frontend/site/category.js'
import "../src/js/frontend/site/stroitelnye_materialy.js";

import "../src/js/jquery.iframe-transport.js";
import "../src/js/jquery.remotipart.js";

import "../src/js/custom.js";

import Vue from "vue/dist/vue.esm";
import VueResource from "vue-resource";
import VueLazyload from "vue-lazyload";
Vue.use(VueResource);
Vue.use(VueLazyload);

import Item from "../Item.vue";
import Items from "../Items.vue";
import forbiznav from "../forbizNav.vue";
import orderForm from "../orderForm.vue";
const qs = require("qs");

const mixin = {
  methods: {
    urlHash: function(obj) {
      history.pushState(null, null, "?" + jQuery.param(obj));
    }
  }
};
document.addEventListener("DOMContentLoaded", () => {
  if (document.getElementById("forbiz_app") != null) {
    const app = new Vue({
      // comments: true,
      el: "#forbiz_app",
      components: {
        Items,
        Item,
        orderForm,
        forbiznav,
        VueLazyload
      },
      methods: {},
      created: function() {
        this.$nextTick(function() {
          if ($("ul#galereya_list a").length > 0) {
            $("ul#galereya_list a").bind({
              mouseenter: function() {
                $(this).addClass("plusOpacity");
              },
              mouseleave: function() {
                $(this).removeClass("plusOpacity");
              }
            });
          }
          if ($(".gallery_show").length > 0) {
            $("div#galereya3").magnificPopup({
              delegate: "a",
              fixedContentPos: false,
              removalDelay: 300,
              mainClass: "mfp-fade",
              type: "image",
              gallery: { enabled: true }
            });
            $("div#div_main").magnificPopup({
              delegate: "a.lazy-image-wrapper",
              fixedContentPos: false,
              removalDelay: 300,
              mainClass: "mfp-fade",
              type: "image"
            });
            $("#galereya3").slick({
              slidesToShow: 1,
              slidesToScroll: 1,
              autoplay: false,
              fade: true,
              arrows: true,
              speed: 200,
              dots: false,
              infinite: true,
              asNavFor: "#galereya3_nav"
            });
            $("#galereya3_nav").slick({
              slidesToShow: 4,
              slidesToScroll: 1,
              asNavFor: "#galereya3",
              dots: false,
              arrows: false,
              focusOnSelect: true
            });
            setTimeout(function() {
              $("#galereya3_nav")
                .find(".img-holder")
                .bind({
                  mouseenter: function() {
                    $(this).addClass("plusOpacity");
                  },
                  mouseleave: function() {
                    $(this).removeClass("plusOpacity");
                  }
                });
            }, 100);
          }

          if ($("#select-other-cats").length > 0) {
            var query_params = qs.parse(location.search);

            if (query_params["?selected_id"] != undefined) {
              var selected_id = query_params["?selected_id"];
              $(".selectpicker_el").hide();
              $(".vendor_" + selected_id).show();
              $(".ptype_" + selected_id).show();
            }
            console.log(
              "query_params['?selected_id'] ",
              query_params["?selected_id"]
            );

            $("#select-other-cats")
              .selectpicker()
              .change(function() {
                var req_vendor = $(this).val();
                if (req_vendor == -1) {
                  $(".selectpicker_el").show();
                  history.pushState(null, null, "?" + jQuery.param({}));
                } else {
                  $(".selectpicker_el").hide();
                  $(".vendor_" + req_vendor).show();
                  $(".ptype_" + req_vendor).show();
                  history.pushState(
                    null,
                    null,
                    "?" + jQuery.param({ selected_id: req_vendor })
                  );
                }
              });
          }

          if ($(".breadcrumbs li").length == 0) {
            $(".breadcrumbs").remove();
          }
        });
      }
    });
  }
});
