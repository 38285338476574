"use strict";

function SpinPreloader() {

    var _this = this;

    /** Показать прелоадер в диве $target.
     *
     * @param $target
     * @param params    {x,y,width,height} - габариты и местонах. области, в которой прелоадер размещён по центру
     * @private
     */
    _this.show = function ($target, params) {

        $target.css('position', 'relative');

        var settings = {
            left  : 0, //$target.offset().left,
            top   : 0, //$target.offset().top,
            right : 'auto',
            width : 'auto', //$target.width(),
            height: $target.height()
        };

        if (params != undefined) {
            $.extend(settings, params);
        }

        var left;
        var top;
        var right;
        var width;
        var height;

        left = settings["left"];
        top = settings["top"];
        right = settings["right"];
        width = settings["width"];
        height = settings["height"];

        var $d = $('<div></div>')
            .addClass('spin_based_preloader')
            .css('left', left + 'px')
            .css('right', (right == 'auto') ? right : right + 'px')
            .css('top', top + 'px')
            .css('width', (width == 'auto') ? width : width + 'px')
            .css('height', height + 'px')
            .append('<span class="helper"></span>')
            .appendTo($target);

        var $a = $('<div class="anim"></div>')
            .appendTo($d);

        var spinner = new Spinner().spin();
        $a[0].appendChild(spinner.el);

        $d.addClass('shown');

    };

    _this.hide = function ($target) {
        var $ilp = $target.find('.spin_based_preloader');
        $ilp.removeClass('shown');
        setTimeout(function () {
            $ilp.remove();
        }, 400);
    };

}

var spinPreloader = new SpinPreloader();