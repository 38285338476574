<template>
  <div v-bind:class="{ with_side_filters: isSubCat, items: true }">
    <div v-if="loading" class="loader"></div>
    <div class="filters" v-if="isSubCat">
      <label :if="showFilters" class="filters_label">
        Найдено
        <b class="red">{{totalItems}}</b>
        {{pluralWord(totalItems, 'товар', 'товара', 'товаров')}}
      </label>

      <div
        v-for="filterProp in filterPropNames"
        :key="filterProp.id"
        v-if="filterProp.id != 36"
        class="filter_select"
      >
        <label v-html="`${filterProp.title}:`"></label>
        <v-select
          :searchable="!isMobile"
          :close-on-select="false"
          v-model="selectedFilters[filterProp.id]"
          :on-change="filtersHasChanges"
          class="forbiz_filters"
          multiple
          :id="filterProp.id"
          :key="filterProp.id"
          :options="filterPropOptions[filterProp.id]"
          disabledkey="unavailable"
          label="label"
          value="value"
        >
          <span slot="no-options">Нет совпадений...</span>
          <template
            @click="this.filtersChange = true"
            slot-scope="option"
            v-html="option"
          ></template>
        </v-select>
      </div>
      <div
        v-else-if="filterProp.id == 36 && vendors != []"
        :key="filterProp.id"
        class="filter_select"
      >
        <label>Бренд:</label>
        <v-select
          :searchable="!isMobile"
          :close-on-select="false"
          v-model="selectedVendors"
          class="forbiz_filters"
          id="brand"
          :on-change="filtersHasChanges"
          multiple
          :options="vendors"
          label="title"
          value="id"
          disabledkey="unavailable"
        >
          <span slot="no-options">Нет совпадений...</span>
        </v-select>
      </div>
      <label :if="showFilters" class="filters_label">
        Найдено
        <b class="red">{{totalItems}}</b>
        {{pluralWord(totalItems, 'товар', 'товара', 'товаров')}}
      </label>
      <div class="filter_select">
        <button
          v-if="filtersExist()"
          class="btn reset_filters"
          @click="resetFilters()"
        >Сбросить фильтры</button>
      </div>
    </div>
    <div class="item_list_wrap clearfix">
      <div class="control" v-if="showControl">
        <div class="sort_price_types" v-if="isSubCat">
          Сортировать по цене:
          <div class="sort_price_types_btns">
            <span
              v-for="sort in sorts"
              :key="sort"
              :class="[{active: sort == currentPriceSortType }, sortClass(sort)]"
              :title="sortTitle(sort)"
              @click="setPriceSortType(sort)"
            ></span>
          </div>
        </div>
        <ul class="per_page_control">
          Показывать товаров:
          <li
            v-for="ppage in ppages"
            :key="ppage"
            :title="`${ppage} на страницу`"
            @click="setCurrentPerPage(ppage)"
            :class="{active: currentPageIsActive(ppage)}"
            v-html="ppage"
          ></li>
        </ul>
        <div class="hitsale_buttons">
          <span
            v-for="btn in ['hit', 'sale']"
            :key="btn"
            @click="toggleHitSale(btn)"
            :class="[{active: hitSaleBtnClass(btn) }, btn]"
          ></span>
        </div>
      </div>
      <div class="items-list clearfix" v-if="totalItems > 0">
        <item
          @order-click="orderFormClick(item,type)"
          v-for="item in items"
          :key="item.id"
          :item="item"
          :hit="item.is_hit"
          :uoms="uoms"
          :sale="item.is_sale"
          :item-price-props="choisePriceArray"
          :item-list-type="true"
        ></item>
      </div>

      <div v-if="showFilters && totalItems == 0" class="no_found_items">
        <h2>Не найдено ни одного товара с указанными фильтрами</h2>
        <p>попробуйте расслабить фильтры...</p>
        <button class="btn reset_filters" @click="resetFilters()">Сбросить фильтры</button>
      </div>

      <div class="pagination_wrap" v-if="totalItems > 0">
        <div class="pagination_new">
          <span
            href="#"
            v-for="pageNumber in totalPages"
            :key="pageNumber"
            v-if="showPage(pageNumber)"
            @click="setPage(pageNumber)"
            :class="{ current: currentPage === pageNumber, last: (pageNumber == totalPages && Math.abs(pageNumber - currentPage) > 7), first: (pageNumber == 0 && Math.abs(pageNumber - currentPage) > 7) }"
            v-html="pageNumber"
          ></span>
          <span v-if="showPage(pageNumber-1) && !showPage(pageNumber)">...</span>
          <i v-else></i>
        </div>
        <div v-if="showPoweredBy" class="powered_by_rubyvkube" v-html="poweredByRubyvkube()"></div>
      </div>
    </div>
    <div class="modals">
      <div
        class="modal fade"
        id="modal_ok"
        tabindex="1"
        role="dialog"
        aria-labelledby="modalOk"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
              <h4 class="modal-title" id="myModalLabel">Сообщение отправлено</h4>
            </div>
            <div class="modal-body">
              <p>Мы скоро с вами свяжемся</p>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-default" data-dismiss="modal">Закрыть</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import "images/btn_hit.png";
import "images/btn_sale.png";
import "images/btn_arrow_up.png";
import "images/btn_arrow_down.png";
import Item from "./Item";
import orderForm from "./orderForm";
import vSelect from "vue-select";
import locale2 from "locale2";
const MobileDetect = require("mobile-detect");

const qs = require("qs");
const includes = require("array-includes");
const scrollToElement = require("scroll-to-element");
const md = new MobileDetect(window.navigator.userAgent);
export default {
  name: "items",
  comments: true,
  components: {
    Item,
    vSelect,
    orderForm
  },
  data: function() {
    return {
      currentPriceSortType: null,
      currentPage: 1,
      items: [],
      totalItems: 0,
      totalPages: 0,
      selectedVendors: [],
      currentPerPage: null,
      selectedFilters: {},
      vendors: [],
      propNames: [],
      uoms: [],
      CategoryPropNames: [],
      sorts: ["lower", "upper"],
      filterPropOptions: {},
      avaliableOptions: {},
      avaliableVendors: [],
      filterPropNamesGetCount: 0,
      filterPropNames: [],
      is_sale: false,
      is_hit: false,
      loading: false,
      initLoad: true,
      showFilters: false,
      orderFormTitle: "",
      orderFormUrl: "",
      filtersChange: false,
      showPoweredBy: false
    };
  },
  props: {
    showControl: {
      type: Boolean,
      required: false
    },
    itemListType: {
      type: Boolean,
      required: false
    },
    categoryId: {
      type: Number,
      required: false
    },
    getVendorId: {
      type: String,
      required: false
    }
  },
  computed: {
    isMobile: function() {
      return md.mobile() != null;
    },
    isSubCat: function() {
      return this.categoryId != null;
    },
    ppages: function() {
      if (this.isSubCat) {
        return [15, 30, 60, 90];
      } else {
        return [16, 32, 64, 96];
      }
    },
    apiUrl: function() {
      if (this.isSubCat) {
        return "/api/v1/categories/" + this.categoryId + "/items";
      } else {
        return "/api/v1/items";
      }
    },
    newPriceProps: function() {
      return this.propNames.filter(
        propName => propName.is_normal_price == true
      );
    },
    allPriceProps: function() {
      return $.map(
        this.propNames.filter(
          propName =>
            propName.related != null || propName.is_normal_price == true
        ),
        function(val) {
          var el = {};
          $.map(val, function(v, k) {
            el[k] = v;
          });
          return el;
        }
      );
    },
    getSelectedVendors: function() {
      return this.selectedVendors.map(vendor => {
        return vendor.id;
      });
    },
    choisePriceArray: function() {
      if (this.isSubCat) {
        return this.pricePropNames;
      } else {
        return this.allPriceProps;
      }
    }
  },
  methods: {
    urlHash: function(obj) {
      //console.log({obj})
      history.pushState(null, null, "?" + jQuery.param(obj));
    },
    recalculateAvaliableOptions: function() {
      for (var key in this.filterPropOptions) {
        for (var filter_option_key in this.filterPropOptions[key]) {
          if (this.avaliableOptions[key] != undefined) {
            this.filterPropOptions[key][filter_option_key][
              "unavailable"
            ] = !includes(
              this.avaliableOptions[key],
              this.filterPropOptions[key][filter_option_key].value
            );
          }
        }
      }
    },
    recalculateAvaliableVendors: function() {
      for (var key in this.vendors) {
        key = parseInt(key);
        this.vendors[key]["unavailable"] = !includes(
          this.avaliableVendors,
          this.vendors[key].id
        );
      }
    },
    isInArray(value, array) {
      return array.indexOf(value) > -1;
    },
    queryParams: function() {
      return {
        page: this.currentPage,
        per_page: this.currentPerPage,
        vendors: this.getSelectedVendors,
        sort_price_type: this.currentPriceSortType,
        hit: this.is_hit,
        sale: this.is_sale,
        filters: this.querySelectedFilters()
      };
    },
    querySelectedFilters: function() {
      //console.log('selectedFilters', this.selectedFilters)
      var fin = {};
      for (var key in this.selectedFilters) {
        //console.log('selectedFilter', this.selectedFilters[key])
        fin[key] = this.getFilterParamsFromKey(key);
      }
      //console.log('fin', fin)
      return fin;
    },
    getFilterParamsFromKey: function(key) {
      var arr = [];
      for (var option_key in this.selectedFilters[key]) {
        arr.push(this.selectedFilters[key][option_key].value);
      }
      return arr;
    },
    getFilterPropValuesFromKey: function(key) {
      var arr = [];
      for (var option_key in this.filterPropOptions[key]) {
        arr.push(this.filterPropOptions[key][option_key].value);
      }
      return arr;
    },
    arrContains: function(a, obj) {
      for (var i = 0; i < a.length; i++) {
        if (a[i] === obj) {
          return true;
        }
      }
      return false;
    },
    pluralWord: function(number, one, two, five) {
      let n = Math.abs(number);
      n %= 100;
      if (n >= 5 && n <= 20) {
        return five;
      }
      n %= 10;
      if (n === 1) {
        return one;
      }
      if (n >= 2 && n <= 4) {
        return two;
      }
      return five;
    },
    showPage: function(pageNumber) {
      return (
        Math.abs(this.currentPage - pageNumber) <= 3 ||
        (pageNumber < 8 && this.currentPage < 5) ||
        (pageNumber > this.totalPages - 8 &&
          this.currentPage > this.totalPages - 5) ||
        (this.totalPages > 1 && pageNumber == 2) ||
        pageNumber == this.totalPages ||
        pageNumber == this.totalPages - 1 ||
        pageNumber == 1
      );
    },
    orderFormClick(item, type) {
      this.orderFormUrl = item.path;
      if (type == "is_ask_price") {
        this.orderFormTitle = "Товар: " + item.title + ", кол-во: ";
      }
      if (type == "order") {
        this.orderFormTitle = "Заявка на " + item.title + ", кол-во: ";
      }
      $("#order_form").modal("show");
    },
    selectedFiltersExist: function() {
      return (
        $.map(this.selectedFilters, function(val, key) {
          if (val != []) {
            return val;
          }
        }).length > 0
      );
    },
    filtersExist: function() {
      return this.getSelectedVendors.length > 0 || this.selectedFiltersExist();
    },
    resetFilters: function() {
      this.items = [];
      this.selectedVendors = [];
      for (var k in this.filterPropOptions) {
        if (
          this.selectedFilters.hasOwnProperty(k) &&
          this.selectedFilters[k].length > 0
        ) {
          this.selectedFilters[k] = [];
        }
      }
      this.is_hit = false;
      this.is_sale = false;
      this.filtersChange = false;
      this.currentPriceSortType = null;
      this.httpGetItems(true);
    },
    getSelectedFilters: function(id) {
      if (this.selectedFilters[id] != undefined) {
        return this.selectedFilters[id];
      } else {
        return [];
      }
    },
    showFilterProp: function(id) {
      return this.filterPropOptions[id] != undefined;
    },
    subCatavaliableOptionsUrl: function(categoryId) {
      return "/api/v1/categories/" + categoryId + "/avaliable_options";
    },
    optionsUrl: function(categoryId, id) {
      return (
        "/api/v1/categories/" + categoryId + "/prop_name/" + id + "/options"
      );
    },
    filtersHasChanges: function() {
      if (this.showFilters == true) {
        this.filtersChange = true;
        this.currentPage = 1;
        this.httpGetItems();
      }
    },
    currentPageIsActive: function(ppage) {
      if (this.currentPerPage == ppage) {
        return true;
      }
    },
    sortTitle: function(sort_type) {
      if (sort_type == 0) {
        return "По увеличению цены";
      }
      if (sort_type == 1) {
        return "По уменьшению цены";
      }
    },
    sortClass: function(sort_type) {
      if (sort_type == "lower") {
        return "asc";
      }
      if (sort_type == "upper") {
        return "desc";
      }
    },
    httpGetItems: function(scrollup) {
      if (!this.loading) {
        this.loading = true;
        if (this.initLoad == true && location.search != "") {
          var query_url_params = qs.parse(location.search);

          if (query_url_params["?page"] != undefined) {
            this.currentPage = query_url_params["?page"];
          }

          if (query_url_params["per_page"] != undefined) {
            this.currentPerPage = query_url_params["per_page"];
          }

          if (query_url_params["hit"] != undefined) {
            this.is_hit = JSON.parse(query_url_params["hit"]);
          }
          if (query_url_params["sale"] != undefined) {
            this.is_sale = JSON.parse(query_url_params["sale"]);
          }

          if (query_url_params["vendors"] != undefined) {
            var tmp_selected_vendors = [];
            query_url_params["vendors"].map(vendor_id => {
              this.vendors.map(vendor => {
                if (vendor.id == parseInt(vendor_id)) {
                  this.selectedVendors.push(vendor);
                }
              });
            });
          }

          if (query_url_params["sort_price_type"] != undefined) {
            this.currentPriceSortType = query_url_params["sort_price_type"];
          }

          if (query_url_params["filters"] != undefined) {
            if (this.selectedFilters != undefined) {
              for (var filter_key in query_url_params["filters"]) {
                for (var filter_prop_id in query_url_params["filters"][
                  filter_key
                ]) {
                  var filter_prop_value =
                    query_url_params["filters"][filter_key][filter_prop_id];
                  filter_key = parseInt(filter_key); //
                  var array_of_filter_prop_values = this.getFilterPropValuesFromKey(
                    filter_key
                  );
                  var position_filter_prop_value_in_filter_prop_options = array_of_filter_prop_values.indexOf(
                    filter_prop_value
                  );
                  if (position_filter_prop_value_in_filter_prop_options != -1) {
                    this.selectedFilters[filter_key].push(
                      this.filterPropOptions[filter_key][
                        position_filter_prop_value_in_filter_prop_options
                      ]
                    );
                  }
                }
              }
            }
          }
        }
        //console.log('this.queryParams', this.queryParams())

        this.$http.get(this.apiUrl, { params: this.queryParams() }).then(
          response => {
            this.totalItems = parseFloat(response.headers.get("X-Total"));
            this.currentPerPage = parseFloat(
              response.headers.get("X-Per-Page")
            );
            this.totalPages = Math.ceil(this.totalItems / this.currentPerPage);
            if (this.totalPages > 0 && this.currentPage > this.totalPages) {
              this.loading = false;
              this.setPage(this.totalPages, true);
            } else {
              if (scrollup) {
                this.scrollUp();
              }
              this.currentPage = parseFloat(response.headers.get("X-Page"));
              this.items = response.body.items;
              this.avaliableOptions = response.body.avaliable_options;
              this.avaliableVendors = response.body.avaliable_vendors;
              this.loading = false;
              this.showFilters = true;
              this.initLoad = false;
              if (this.filtersChange) {
                this.urlHash(this.queryParams());
              }
            }
          },
          response => {}
        );
      }
    },
    setCurrentPerPage: function(new_per_page) {
      if (!this.loading && this.currentPerPage != new_per_page) {
        this.currentPerPage = new_per_page;
        this.urlHash(this.queryParams());
        this.httpGetItems();
      }
    },
    setPage: function(pageNumber, force) {
      if ((!this.loading && this.currentPage != pageNumber) || force == true) {
        this.filtersChange = true;
        this.currentPage = pageNumber;
        this.urlHash(this.queryParams());
        this.httpGetItems(true);
      }
    },
    scrollUp: function() {
      if (this.isSubCat) {
        scrollToElement("ul.breadcrumbs", {
          offset: 15,
          ease: "out-quad",
          duration: 1000
        });
      } else {
        scrollToElement(".container h1.title", {
          offset: -20,
          ease: "out-quad",
          duration: 1000
        });
      }
    },
    setPriceSortType: function(new_type) {
      if (!this.loading) {
        this.filtersChange = true;
        if (this.currentPriceSortType != new_type) {
          this.currentPriceSortType = new_type;
        } else {
          this.currentPriceSortType = null;
        }
        this.currentPage = 1;
        this.httpGetItems();
      }
    },
    getItemPath: function(category_id, item_id) {
      return "/stroitelnye-materialy/" + category_id + "/" + item_id;
    },
    toggleHitSale: function(key) {
      this.filtersChange = true;
      if (!this.loading) {
        if (key == "hit") {
          this.is_hit = !this.is_hit;
        } else if (key == "sale") {
          this.is_sale = !this.is_sale;
        }
        this.currentPage = 1;
        this.httpGetItems();
      }
    },
    hitSaleBtnClass: function(btn) {
      if (btn == "hit") {
        return this.is_hit;
      } else if (btn == "sale") {
        return this.is_sale;
      }
    },
    poweredByRubyvkube: function() {
      if (locale2 == "ru-RU") {
        return 'Разработано с <span class="heart">♥</span> и <a class="vue" target="_blank" href="https://ru.vuejs.org">VueJs</a><br> в <a target="_blank" href="https://rubyvkube.ru/ru/works/forbiz">rubyvkube.ru</a>.';
      } else {
        return 'Filters made with <span class="heart">♥</span> and <a class="vue" target="_blank" href="https://ru.vuejs.org">VueJs</a><br> by <a target="_blank" href="https://rubyvkube.ru/en/works/forbiz">rubyvkube.ru</a>.';
      }
    },
    getGroupedPropNames() {
      this.$http
        .get(
          "/api/v1/categories/" +
            this.categoryId +
            "/category_prop_names_grouped"
        )
        .then(
          success => {
            this.filterPropNames = success.body.filter_props;
            this.pricePropNames = success.body.price_props;
            this.commonPropNames = success.body.common_props;
            this.mainPropNames = success.body.main_props;
            this.filterPropNames.map(filter_prop => {
              this.$http
                .get(this.optionsUrl(this.categoryId, filter_prop.id))
                .then(
                  success => {
                    this.filterPropOptions[filter_prop.id] =
                      success.body.options;
                    for (var opt in this.filterPropOptions[filter_prop.id]) {
                      this.filterPropOptions[filter_prop.id][
                        opt
                      ].unavailable = false;
                    }
                    this.selectedFilters[filter_prop.id] = [];
                    this.filterPropNamesGetCount++;
                    if (
                      this.filterPropNamesGetCount ==
                      this.filterPropNames.length
                    ) {
                      this.httpGetItems(true);
                    }
                  },
                  fail => {
                    return null;
                  }
                );
            });
          },
          fail => {
            return null;
          }
        );
    }
  },
  mounted: function() {
    this.$nextTick(function() {
      this.currentPerPage = this.ppages[0];
      if (this.isSubCat) {
        this.$http
          .get("/api/v1/categories/" + this.categoryId + "/vendors")
          .then(
            success => {
              this.vendors = success.body;
              if (this.getVendorId != "") {
                this.vendors.map(vendor => {
                  vendor.unavailable = false;
                  if (vendor.id == parseFloat(this.getVendorId)) {
                    this.selectedVendors = [vendor];
                  }
                });
              }
              this.getGroupedPropNames();
            },
            fail => {
              return null;
            }
          );
      } else {
        this.$http.get("/api/v1/prop_names").then(
          success => {
            this.propNames = success.body;
            this.httpGetItems(true);
          },
          fail => {
            return null;
          }
        );
      }
      this.$http.get("/api/v1/uoms").then(
        success => {
          this.uoms = success.body;
        },
        fail => {
          return null;
        }
      );
    });
  }
};
</script>
<style lang="scss">
#forbiz_products {
  margin: 20px 0px 20px 0px;
  overflow: hidden;
}
.asd-enter-active,
.asd-leave-active {
  transition: opacity 0.5s;
}
.asd-enter,
.asd-leave-to {
  opacity: 1;
}
.filters {
  width: 200px;
  float: left;
  margin-bottom: 20px;
  .filters_label,
  .filter_select label {
    font-weight: normal;
    font-size: 16px;
    letter-spacing: 0.5px;
    .red {
      color: #c81118;
    }
  }
  .filters_label {
    margin-top: 18px;
  }
  .filter_select {
    margin-top: 10px;
  }
}
.forbiz_filters {
  &.v-select {
    .dropdown-menu {
      position: relative;
      margin: -2px 0px 10px 0px;
      li {
        display: block;
        height: auto;
        width: 100%;
        &.active {
          a {
            background: #c81118;
            color: white;
            &:hover,
            &:focus {
              background: #c81118;
              color: white;
            }
          }
        }
        &.highlight {
          a {
            border: 2px solid #c81118;
            padding: 2px 4px;
            background: white;
            color: #444;
          }
        }
        a {
          display: inline-block;
          font-size: 16px;
          margin: 1px 0px;
          padding: 4px 6px;
          width: 100%;
          font-family: sans-serif;
          white-space: normal;
        }
      }
    }
    .selected-tag {
      padding-right: 20px;
      height: auto;
      display: block;
      position: relative;
      margin: 4px 3px 0 3px;
      max-width: 190px;
      word-wrap: break-word;
      font-family: sans-serif;
      .close {
        position: absolute;
        top: 3px;
        right: 5px;
        font-weight: 100;
      }
    }
    .open-indicator:before {
      border-width: 1px 1px 0 0;
    }
  }
}
.items {
  display: block;
  padding: 0 45px 20px;
  margin: 0 auto;
  background: none;
  .item ul.price_info li:last-child {
    margin-right: 20px;
  }
  &.with_side_filters {
    display: block;
    padding: 0 10px 20px;
    background: none;
    overflow: hidden;
    margin: 10px 0px 20px 0px;
    .item_list_wrap {
      width: 680px;
      float: left;
      padding: 0px 0px 0px 20px;
      .item {
        width: 215px;
      }
    }
    .control {
      .hitsale_buttons {
        margin: 0px;
        span:first-child {
          margin-right: 0px;
        }
      }
    }
  }
  .control {
    //width: 100%;
    clear: both;
    padding: 10px 0px;
    color: #444;
    font-size: 15px;
    text-align: center;
    margin-bottom: 10px;
    .hitsale_buttons {
      display: inline-block;
      vertical-align: middle;
      margin-left: 200px;
      span {
        display: block;
        float: right;
        width: 103px;
        height: 33px;
        overflow: hidden;
        margin-right: 10px;
        outline: none;
        border-radius: 6px;
        -webkit-box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.2);
        -moz-box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.2);
        box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.2);
        -ms-filter: "progid:DXImageTransform.Microsoft.Shadow(Strength=$val, Direction=135, Color='#000000')";
        filter: "progid: DXImageTransform.Microsoft.Shadow(Strength=$val, Direction=135, Color='#000000')";
        opacity: 0.6;
        background-color: transparent;
        background-repeat: no-repeat;
        &:hover {
          cursor: pointer;
        }
        &.active {
          -webkit-filter: brightness(125%);
          -moz-filter: brightness(125%);
          -ms-filter: brightness(125%);
          -o-filter: brightness(125%);
          filter: brightness(125%);
          border: 3px solid rgba(0, 0, 0, 0.76);
          background-position: -3px -3px;
          opacity: 1;
        }
        &.hit {
          background-image: url("../assets/images/btn_hit.png");
        }
        &.sale {
          background-image: url("../assets/images/btn_sale.png");
        }
      }
    }
    .per_page_control {
      margin-top: 5px;
      margin-right: 20px;
      float: right;
      vertical-align: middle;
      padding-left: 0px;
      li {
        cursor: pointer;
        display: inline-block;
        line-height: 20px;
        height: 20px;
        margin: 0px 1px;
        padding: 0px 3px;
        text-align: center;
        color: #444;
        text-decoration: none;
        border-bottom: 1px dotted #444;
        outline: none;
        &.active {
          color: #9e1717;
          text-decoration: none;
          border-bottom: none;
        }
        &:hover {
          border-bottom: none;
          text-decoration: none;
        }
      }
    }
    .sort_price_types {
      float: left;
      vertical-align: middle;
      width: 155px;
      margin-top: 8px;
      text-align: left;
      .sort_price_types_btns {
        float: right;
        margin-top: 6px;
        &:first-child {
          margin-right: 0px;
        }
        span {
          cursor: pointer;
          display: block;
          float: left;
          width: 15px;
          height: 10px;
          overflow: hidden;
          outline: none;
          &.asc {
            background: transparent url("../assets/images/btn_arrow_up.png")
              no-repeat 0 0;
            &.active {
              background-position: 0 -10px;
            }
          }
          &.desc {
            background: transparent url("../assets/images/btn_arrow_down.png")
              no-repeat 0 0;
            &.active {
              background-position: 0 -10px;
            }
          }
        }
      }
    }
  }
  .price_sorting {
    float: left;
    width: 187px;
  }
}
.loader {
  height: 1px;
  width: 100%;
  position: fixed;
  left: 0px;
  top: 0px;
  overflow: hidden;
  background-color: lighten(#486077, 40%);
  z-index: 300;
}
.loader:before {
  display: block;
  position: absolute;
  content: "";
  left: -200px;
  width: 200px;
  height: 1px;
  background-color: #486077;
  animation: loading 2s linear infinite;
}
@keyframes loading {
  from {
    left: -200px;
    width: 30%;
  }
  50% {
    width: 30%;
  }
  70% {
    width: 70%;
  }
  80% {
    left: 50%;
  }
  95% {
    left: 120%;
  }
  to {
    left: 100%;
  }
}
.no_found_items {
  padding: 120px 40px 0px;
  text-align: center;
  p {
    font-size: 18px;
  }
}
.reset_filters {
  padding: 8px 16px;
  background: #e9353b;
  color: white;
}
.filters {
  .reset_filters {
    width: 100%;
    display: block;
    &:hover {
      color: white !important;
    }
  }
}
.pagination_wrap {
  width: 100%;
  clear: both;
  float: left;
  padding: 0px;
  margin: 10px 0px 0px 0px;
  .pagination_new {
    display: inline-block;
    margin: 0;
    span {
      &.previous_page {
        display: none;
      }
      &.next_page {
        display: none;
      }
    }
    .current {
      display: inline-block;
      line-height: 29px;
      width: 30px;
      height: 30px;
      text-align: center;
      font-weight: bold !important;
      font-style: normal !important;
      border: 1px solid #b6b6b6;
      background-color: #e9353b;
      color: #ffffff;
    }
    em {
      display: inline-block;
      line-height: 29px;
      width: 30px;
      height: 30px;
      text-align: center;
      font-weight: bold !important;
      font-style: normal !important;
      border: 1px solid #b6b6b6;
      background-color: #e9353b;
      color: #ffffff;
    }
    a,
    span {
      cursor: pointer;
      margin: 1px;
      display: inline-block;
      line-height: 29px;
      width: 30px;
      height: 30px;
      border: 1px solid #b6b6b6;
      background-color: #ffffff;
      color: #777777;
      text-align: center;
      font-weight: bold !important;
      text-decoration: none;
      &:hover,
      &:active,
      &:focus {
        text-decoration: none !important;
        color: black;
      }
      &.previous_page {
        display: none;
      }
      &.next_page {
        display: none;
      }
    }
  }
}
.powered_by_rubyvkube {
  display: none;
  text-align: right;
  color: #888;
  line-height: 1;
  padding: 2px 0px 0px 0px;
  .heart {
    //color: red;
  }
  .vue {
    color: #795;
  }
  display: block;
  float: right;
  top: 0px;
  right: 0px;
}
</style>
