$(document).ready(function() {

    try {
        ymaps.ready(init);
    } catch(e) {
        //console.log("ERROR ymaps init: ");
        //console.log(e);
    }

    function init () {

        // определяем координаты объектов
        var coord_101 = [SITE_PROPS_LATITUDE, SITE_PROPS_LONGITUDE];

        /*_initFooterMap(coord_101);*/
        _initContactsMap(coord_101);

    }

    //--[ футер ]-------------------------------------------------------
    /*function _initFooterMap(coord_101) {

        if ($("#footer_map").length != 0) {
            var myMap_footer = new ymaps.Map("footer_map", {
                center: coord_101,
                zoom: 12,
                controls: ['zoomControl']
            });

            var myPlacemark2 = new ymaps.Placemark(coord_101, {}, {
                iconLayout: 'default#image',
                iconImageHref: MAP_MARKER,
                iconImageSize: [57, 57],
                iconImageOffset: [-28, -51]
            });

            myMap_footer.geoObjects.add(myPlacemark2);
        }

    }*/

    //--[ страница "контакты" ]-------------------------------------------------------
  function _initContactsMap(coord_101) {

    try {
      if ($("#map").length != 0) {
        var myMap = new ymaps.Map("map", {
          center: coord_101,
          zoom: 15,
          controls: ['zoomControl', 'typeSelector', 'fullscreenControl']
        });

        // лабаем и вставляем указатели для строительного
        var myPlacemark = new ymaps.Placemark(coord_101, {
           hintContent: MAP_HIT_CONTENT
        }, {
          iconLayout: 'default#image',
          iconImageHref: MAP_MARKER,
          iconImageSize: [57, 57],
          iconImageOffset: [-28, -51]
        });
        myMap.geoObjects.add(myPlacemark);
      }
    } catch(e) {

    }

  }

});