<template>
  <div class="modals">
    <button @click="showModal" :class="buttonClass">{{ButtonText}}</button>
    <div class="modal fade in" v-if="showSuccessModalWindow" id="modal_ok" tabindex="1" role="dialog" aria-labelledby="modalOk" aria-hidden="true" >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close" @click="showSuccessModalWindow = false" data-dismiss="modal" aria-hidden="true">×</button>
            <h4 class="modal-title" id="myModalLabel">Сообщение отправлено</h4>
          </div>
          <div class="modal-body"><p>Мы скоро с вами свяжемся</p></div>
          <div class="modal-footer">
            <button type="button" class="btn btn-default" @click="showSuccessModalWindow = false" data-dismiss="modal">Закрыть</button>
          </div>
        </div>
      </div>
    </div>
    <div id="order_form" class="modal fade in" :class="isAskPriceClass" v-if="showModalWindow" >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close" @click="showModalWindow = false" data-dismiss="modal" aria-hidden="true">&times;</button>
            <h4 class="modal-title medium" id="myModalLabel" v-html="this.orderFormSubTitle"></h4>
          </div>
          <div class="modal-body regular">
            <form role="form" id="form_order" data-model="message" action="/message_order" accept-charset="UTF-8" data-remote="true" method="post">
              <input name="utf8" type="hidden" value="✓">
              <div :class="{'has-error': isNotBlank('name')}" class="form-group clearfix">
                <label class="control-label must" for="message_name">Имя</label>
                <input class="form-control" type="text" v-model="message.name" name="message[name]" id="mess_name">
                <span class="help-block col-md-push-4 col-md-8" v-if="isNotBlank('name')">{{errors.name}}</span>
              </div>
              <div :class="{'has-error': isNotBlank('email_or_phone')}" class="form-group clearfix">
                <label class="control-label must" for="message_email">Email или телефон</label>
                <input class="form-control" type="text" v-model="message.email" name="message[email]" id="mess_email_or_phone">
                <span class="help-block col-md-push-4 col-md-8" v-if="isNotBlank('email_or_phone')">{{errors.email}}</span>
              </div>
              <div :class="{'has-error': isNotBlank('kapcha')}" class="form-group kapcha clearfix">
                <label class="control-label" for="mess_kapcha">Капча: сколько будет 2+2?</label>
                <input class="form-control" type="text" v-model="message.kapcha" id="mess_kapcha">
                <span class="help-block col-md-12" v-if="isNotBlank('kapcha')">{{errors.kapcha}}</span>
              </div>
              <input type="text" hidden="true" v-model="message.source" >
              <div :class="{hasError: isNotBlank('content')}" class="form-group clearfix" style="margin-bottom: 0;">
                <label class="control-label" for="message_content">Комментарий</label>
                <textarea class="form-control" rows="4" v-model="message.content" id="message_content" data-original-title="" title=""></textarea>
                <span class="help-block col-md-push-4 col-md-8" v-if="isNotBlank('content')">{{errors.content}}</span>
              </div>
             <div class="actions clearfix" style="margin-top: 15px;">
                <label class="control-label" for="mess_ "> </label>
                <input type="submit" @click="onSubmit" name="commit" value="Отправить" class="red_button red_button_submit" data-disable-with="Отправляется..." style="">
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn_close btn-default" data-dismiss="modal">Закрыть</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'OrderForm',
    props: {
      orderFormTitle: {
        type: String,
        required: true
      },
      orderFormUrl: {
        type: String,
        required: true
      },
      formType: {
        type: String,
        required: true
      },
      ButtonText: {
        type: String,
        required: true
      }
    },
    data: function(){
      return {
        showModalWindow: false,
        showSuccessModalWindow: false,
        message: {
          name: '',
          email: '',
          content: '',
          item_url: '',
          source: ''
        },
        errors: {
          content: '',
          email: '',
          name: '',
          item_url: ''
        }
        
      }
    },
    methods: {
      isNotBlank: function(key){
        return (this.errors[key] != '')
      },
      showModal: function(){
        this.showModalWindow = true
        setTimeout(function() {
          $('#order_form').modal('show')
        }, 170);
      },
      clearErrors: function() {
        this.errors.content = ''
        this.errors.email = ''
        this.errors.name = ''
        this.errors.item_url = ''
      },
      onSubmit: function(e){
        e.preventDefault()
        var that = this  
        this.clearErrors()
        this.$http.post('/messages', { message: this.message }, {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
            'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
          },
          emulateJSON: true
        }).then(function(response,body) {
          //console.log('response.body success',response.body)
          this.showModalWindow = false
          this.showSuccessModalWindow = true
        }, function(response, body) {
          console.log('response.body err',response.body)
          $.map(response.body, function(val, key){
            that.errors[key] = val.join(', ')
          })
        });
      }
    },
    computed:{
      isAskPrice: function(){
        if (this.formType == 'is_ask_price') {return true }
        else {return false}
      },
      isAskPriceClass: function(){
        if (this.isAskPrice) {
          return 'is_ask_price_h'
        } else { return '' }
      },
      backendUrl: function(){
        return  '/messages'
      },
      orderFormSubTitle: function(){
        if (this.isAskPrice) { return 'Мы отправим запрашиваемую информацию удобным для Вас способом'}
        else { return 'ЗАЯВКА' }
      },
      orderFormText: function(){
        if (this.formType == 'is_ask_price') {
          return 'Товар: '+this.orderFormTitle+', кол-во: '
        } else {
          return 'Заявка на '+this.orderFormTitle +', кол-во: '
        }
      },
      buttonClass: function(){
        if (this.isAskPrice) { return 'is_ask_price'}
        else { return 'button-red no-outline bold order_form_button'}
      },
      isAskPriceId: function(){
        
      },
      orderFormFullUrl: function(){
        return  window.location.protocol + '//' + window.location.host + this.orderFormUrl
      }
    },
    mounted: function () {
      this.$nextTick(function () {
        this.message.content = this.orderFormText
        this.message.source = this.formType
        this.message.item_url = this.orderFormFullUrl
      })
    }
  }
</script>

<style scoped lang="scss">
  .modals{
    position: absolute;
    bottom: 12px;

    .red_button_submit{
      line-height: 34px;
      font-size: 16px;
      color: white;
      text-transform: uppercase;
      border-radius: 4px;
    }
    .items-list .is_ask_price{
      width: 180px;
      top: 0px;
    }
    .is_ask_price{
      margin-top: 30px;
      width: 180px;
      height: 36px;
      bottom: 14px;
      line-height: 23px;
      padding: 5px;
      border: 1px solid #c6c4c4;
      border-radius: 3px;
      cursor: pointer;
      text-align: center;
      font-size: 15px;
      display: inline-block;
      background-color: rgba(255,0,0,.12);
    }
    .is_ask_price_h{
      h4.modal-title{
        font-size: 15px;
        text-transform: none;
        font-family: Tahoma, Arial, sans-serif;
      }
    }
    #mess_comment{
      font-family: system-ui;
      min-height: 130px;
    }
  }
</style>