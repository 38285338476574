$(document).ready(function(){
  $('a.no-clickable').click(function (e) {
    e.preventDefault();
  });
      
      
  $('#slider').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    fade:true,
    autoplaySpeed: 5000,
    arrows:true,
    speed: 200,
    dots:true,
    infinite: true
  });
      
})


